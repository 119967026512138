import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import FooterWaves from '../components/footer-waves';

function Datenschutzerklaerung() {
  return (
    <Layout>
      <SEO
        title="Datenschutzerklärung"
        description="Der Schutz Ihrer persönlichen Daten ist uns wichtig. Sehen Sie, wie wir Ihre Daten nutzen. DSGVO gerecht."
      />

      {/* Alternate features */}
      <section className="bg-white py-8 mt-10 p-6">
        <div className="container max-w-5xl mx-auto m-8 text-gray-800">
          <h1 className="">Datenschutzerklärung</h1>
          <h2>1. Datenschutz auf einen Blick</h2>
          <h3>Allgemeine Hinweise</h3>
          <p>
            Die folgenden Hinweise geben einen einfachen Überblick darüber, was
            mit Ihren personenbezogenen Daten passiert, wenn Sie unsere Website
            besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
            persönlich identifiziert werden können. Ausführliche Informationen
            zum Thema Datenschutz entnehmen Sie unserer unter diesem Text
            aufgeführten Datenschutzerklärung.
          </p>
          <h3>Datenerfassung auf unserer Website</h3>
          <p>
            <strong>
              Wer ist verantwortlich für die Datenerfassung auf dieser Website?
            </strong>
          </p>
          <p>
            Die Datenverarbeitung auf dieser Website erfolgt durch den
            Websitebetreiber. Dessen Kontaktdaten können Sie dem Impressum
            dieser Website entnehmen.
          </p>
          <p>
            <strong>Wie erfassen wir Ihre Daten?</strong>
          </p>
          <p>
            Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
            mitteilen. Hierbei kann es sich z.B. um Daten handeln, die Sie in
            ein Kontaktformular eingeben.
          </p>
          <p>
            Andere Daten werden automatisch beim Besuch der Website durch unsere
            IT-Systeme erfasst. Das sind vor allem technische Daten (z.B.
            Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die
            Erfassung dieser Daten erfolgt automatisch, sobald Sie unsere
            Website betreten.
          </p>
          <p>
            <strong>Wofür nutzen wir Ihre Daten?</strong>
          </p>
          <p>
            Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung
            der Website zu gewährleisten. Andere Daten können zur Analyse Ihres
            Nutzerverhaltens verwendet werden.
          </p>
          <p>
            <strong>Welche Rechte haben Sie bezüglich Ihrer Daten?</strong>
          </p>
          <p>
            Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft,
            Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu
            erhalten. Sie haben außerdem ein Recht, die Berichtigung, Sperrung
            oder Löschung dieser Daten zu verlangen. Hierzu sowie zu weiteren
            Fragen zum Thema Datenschutz können Sie sich jederzeit unter der im
            Impressum angegebenen Adresse an uns wenden. Des Weiteren steht
            Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
          </p>
          <h3>Analyse-Tools und Tools von Drittanbietern</h3>
          <p>
            Beim Besuch unserer Website kann Ihr Surf-Verhalten statistisch
            ausgewertet werden. Das geschieht vor allem mit Cookies und mit
            sogenannten Analyseprogrammen. Die Analyse Ihres Surf-Verhaltens
            erfolgt in der Regel anonym; das Surf-Verhalten kann nicht zu Ihnen
            zurückverfolgt werden. Sie können dieser Analyse widersprechen oder
            sie durch die Nichtbenutzung bestimmter Tools verhindern.
            Detaillierte Informationen dazu finden Sie in der folgenden
            Datenschutzerklärung.
          </p>
          <p>
            Sie können dieser Analyse widersprechen. Über die
            Widerspruchsmöglichkeiten werden wir Sie in dieser
            Datenschutzerklärung informieren.
          </p>
          <h2>2. Allgemeine Hinweise und Pflichtinformationen</h2>
          <h3>Datenschutz</h3>
          <p>
            Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen
            Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
            vertraulich und entsprechend der gesetzlichen
            Datenschutzvorschriften sowie dieser Datenschutzerklärung.
          </p>
          <p>
            Wenn Sie diese Website benutzen, werden verschiedene
            personenbezogene Daten erhoben. Personenbezogene Daten sind Daten,
            mit denen Sie persönlich identifiziert werden können. Die
            vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben
            und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem
            Zweck das geschieht.
          </p>
          <p>
            Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B.
            bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann.
            Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist
            nicht möglich.
          </p>
          <h3>Hinweis zur verantwortlichen Stelle</h3>
          <p>
            Die verantwortliche Stelle für die Datenverarbeitung auf dieser
            Website ist:
          </p>
          <p>
            Novalab
            <br />
            Florian Moser
            <br />
            Oberfeld 21
            <br />
            3274 Bühl
          </p>
          <p>
            Telefon: 031 333 50 88
            <br />
            E-Mail: info@novalab.ch
          </p>
          <p>
            Verantwortliche Stelle ist die natürliche oder juristische Person,
            die allein oder gemeinsam mit anderen über die Zwecke und Mittel der
            Verarbeitung von personenbezogenen Daten (z.B. Namen,
            E-Mail-Adressen o. Ä.) entscheidet.
          </p>
          <h3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>
          <p>
            Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
            Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
            jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per
            E-Mail an uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
            Datenverarbeitung bleibt vom Widerruf unberührt.
          </p>
          <h3>Recht auf Datenübertragbarkeit</h3>
          <p>
            Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
            oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich
            oder an einen Dritten in einem gängigen, maschinenlesbaren Format
            aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten
            an einen anderen Verantwortlichen verlangen, erfolgt dies nur,
            soweit es technisch machbar ist.
          </p>
          <h3>SSL- bzw. TLS-Verschlüsselung</h3>
          <p>
            Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der
            Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen
            oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine
            SSL-bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung
            erkennen Sie daran, dass die Adresszeile des Browsers von “http://”
            auf “https://” wechselt und an dem Schloss-Symbol in Ihrer
            Browserzeile.
          </p>
          <p>
            Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die
            Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen
            werden.
          </p>
          <h3>Auskunft, Sperrung, Löschung</h3>
          <p>
            Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen
            jederzeit das Recht auf unentgeltliche Auskunft über Ihre
            gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger
            und den Zweck der Datenverarbeitung und ggf. ein Recht auf
            Berichtigung, Sperrung oder Löschung dieser Daten. Hierzu sowie zu
            weiteren Fragen zum Thema personenbezogene Daten können Sie sich
            jederzeit unter der im Impressum angegebenen Adresse an uns wenden.
          </p>
          <h2>3. Datenerfassung auf unserer Website</h2>
          <h3>Cookies</h3>
          <p>
            Die Internetseiten verwenden teilweise so genannte Cookies. Cookies
            richten auf Ihrem Rechner keinen Schaden an und enthalten keine
            Viren. Cookies dienen dazu, unser Angebot nutzerfreundlicher,
            effektiver und sicherer zu machen. Cookies sind kleine Textdateien,
            die auf Ihrem Rechner abgelegt werden und die Ihr Browser speichert.
          </p>
          <p>
            Die meisten der von uns verwendeten Cookies sind so genannte
            “Session-Cookies”. Sie werden nach Ende Ihres Besuchs automatisch
            gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert bis
            Sie diese löschen. Diese Cookies ermöglichen es uns, Ihren Browser
            beim nächsten Besuch wiederzuerkennen.
          </p>
          <p>
            Sie können Ihren Browser so einstellen, dass Sie über das Setzen von
            Cookies informiert werden und Cookies nur im Einzelfall erlauben,
            die Annahme von Cookies für bestimmte Fälle oder generell
            ausschließen sowie das automatische Löschen der Cookies beim
            Schließen des Browser aktivieren. Bei der Deaktivierung von Cookies
            kann die Funktionalität dieser Website eingeschränkt sein.
          </p>
          <p>
            Cookies, die zur Durchführung des elektronischen
            Kommunikationsvorgangs oder zur Bereitstellung bestimmter, von Ihnen
            erwünschter Funktionen (z.B. Warenkorbfunktion) erforderlich sind,
            werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert. Der
            Websitebetreiber hat ein berechtigtes Interesse an der Speicherung
            von Cookies zur technisch fehlerfreien und optimierten
            Bereitstellung seiner Dienste. Soweit andere Cookies (z.B. Cookies
            zur Analyse Ihres Surfverhaltens) gespeichert werden, werden diese
            in dieser Datenschutzerklärung gesondert behandelt.
          </p>
          <h3>Server-Log-Dateien</h3>
          <p>
            Der Provider der Seiten erhebt und speichert automatisch
            Informationen in so genannten Server-Log-Dateien, die Ihr Browser
            automatisch an uns übermittelt. Dies sind:
          </p>
          <ul>
            <li>Browsertyp und Browserversion</li>
            <li>verwendetes Betriebssystem</li>
            <li>Referrer URL</li>
            <li>Hostname des zugreifenden Rechners</li>
            <li>Uhrzeit der Serveranfrage</li>
            <li>IP-Adresse</li>
          </ul>
          <p>
            Eine Zusammenführung dieser Daten mit anderen Datenquellen wird
            nicht vorgenommen.
          </p>
          <p>
            Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. f DSGVO,
            der die Verarbeitung von Daten zur Erfüllung eines Vertrags oder
            vorvertraglicher Maßnahmen gestattet.
          </p>
          <h3>Kontaktformular</h3>
          <p>
            Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden
            Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort
            angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den
            Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir
            nicht ohne Ihre Einwilligung weiter.
          </p>
          <p>
            Die Verarbeitung der in das Kontaktformular eingegebenen Daten
            erfolgt somit ausschließlich auf Grundlage Ihrer Einwilligung (Art.
            6 Abs. 1 lit. a DSGVO). Sie können diese Einwilligung jederzeit
            widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns.
            Die Rechtmäßigkeit der bis zum Widerruf erfolgten
            Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
          </p>
          <p>
            Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei
            uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur
            Speicherung widerrufen oder der Zweck für die Datenspeicherung
            entfällt (z.B. nach abgeschlossener Bearbeitung Ihrer Anfrage).
            Zwingende gesetzliche Bestimmungen – insbesondere
            Aufbewahrungsfristen – bleiben unberührt.
          </p>
          <h2>4. Analyse Tools und Werbung</h2>
          <h3>Matomo (ehemals Piwik)</h3>
          <p>
            Diese Website benutzt den Open Source Webanalysedienst Matomo.
            Matomo verwendet so genannte „Cookies“. Das sind Textdateien, die
            auf Ihrem Computer gespeichert werden und die eine Analyse der
            Benutzung der Website durch Sie ermöglichen. Dazu werden die durch
            den Cookie erzeugten Informationen über die Benutzung dieser Website
            auf unserem Server gespeichert. Die IP-Adresse wird vor der
            Speicherung anonymisiert.
          </p>
          <p>
            Matomo-Cookies verbleiben auf Ihrem Endgerät, bis Sie sie löschen.
          </p>
          <p>
            Die Speicherung von Matomo-Cookies erfolgt auf Grundlage von Art. 6
            Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
            Interesse an der anonymisierten Analyse des Nutzerverhaltens, um
            sowohl sein Webangebot als auch seine Werbung zu optimieren.
          </p>
          <p>
            Die durch den Cookie erzeugten Informationen über die Benutzung
            dieser Website werden nicht an Dritte weitergegeben. Sie können die
            Speicherung der Cookies durch eine entsprechende Einstellung Ihrer
            Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass
            Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser
            Website vollumfänglich werden nutzen können.
          </p>
          <p>
            Wenn Sie mit der Speicherung und Nutzung Ihrer Daten nicht
            einverstanden sind, können Sie die Speicherung und Nutzung hier
            deaktivieren. In diesem Fall wird in Ihrem Browser ein
            Opt-Out-Cookie hinterlegt, der verhindert, dass Matomo Nutzungsdaten
            speichert. Wenn Sie Ihre Cookies löschen, hat dies zur Folge, dass
            auch das Matomo Opt-Out-Cookie gelöscht wird. Das Opt-Out muss bei
            einem erneuten Besuch unserer Seite wieder aktiviert werden.
          </p>
          <p>
            <iframe
              className="w-full"
              src="https://piwik.novalab.ch/index.php?module=CoreAdminHome&amp;action=optOut&amp;language=de&amp;backgroundColor=&amp;fontColor=&amp;fontSize=&amp;fontFamily=Arial"
            ></iframe>
          </p>
          <h3>Hotjar</h3>{' '}
          <p>
            Diese Website nutzt Hotjar. Anbieter ist die Hotjar Ltd., Level 2,
            St Julians Business Centre, 3, Elia Zammit Street, St Julians STJ
            1000, Malta, Europe (Website:{' '}
            <a href="" target="_blank" rel="noopener noreferrer">
              https://www.hotjar.com
            </a>
            ).
          </p>{' '}
          <p>
            Hotjar ist ein Werkzeug zur Analyse Ihres Nutzerverhaltens auf
            dieser Website. Mit Hotjar können wir u. a. Ihre Maus- und
            Scrollbewegungen und Klicks aufzeichnen. Hotjar kann dabei auch
            feststellen, wie lange Sie mit dem Mauszeiger auf einer bestimmten
            Stelle verblieben sind. Aus diesen Informationen erstellt Hotjar
            sogenannte Heatmaps, mit denen sich feststellen lässt, welche
            Websitebereiche vom Websitebesucher bevorzugt angeschaut werden.
          </p>{' '}
          <p>
            Des Weiteren können wir feststellen, wie lange Sie auf einer Seite
            verblieben sind und wann Sie sie verlassen haben. Wir können auch
            feststellen, an welcher Stelle Sie Ihre Eingaben in ein
            Kontaktformular abgebrochen haben (sog. Conversion-Funnels).
          </p>{' '}
          <p>
            Darüber hinaus können mit Hotjar direkte Feedbacks von
            Websitebesuchern eingeholt werden. Diese Funktion dient der
            Verbesserung der Webangebote des Websitebetreibers.
          </p>{' '}
          <p>
            Hotjar verwendet Cookies. Cookies sind kleine Textdateien, die auf
            Ihrem Rechner abgelegt werden und die Ihr Browser speichert. Sie
            dienen dazu, unser Angebot nutzerfreundlicher, effektiver und
            sicherer zu machen. Mit diesen Cookies lässt sich insbesondere
            feststellen, ob diese Website mit einem bestimmten Endgerät besucht
            wurde oder ob die Funktionen von Hotjar für den betreffenden Browser
            deaktiviert wurden. Hotjar-Cookies verbleiben auf Ihrem Endgerät,
            bis Sie sie löschen.
          </p>{' '}
          <p>
            Sie können Ihren Browser so einstellen, dass Sie über das Setzen von
            Cookies informiert werden und Cookies nur im Einzelfall erlauben,
            die Annahme von Cookies für bestimmte Fälle oder generell
            ausschließen sowie das automatische Löschen der Cookies beim
            Schließen des Browsers aktivieren. Bei der Deaktivierung von Cookies
            kann die Funktionalität dieser Website eingeschränkt sein.
          </p>{' '}
          <p>
            Die Nutzung von Hotjar und die Speicherung von Hotjar-Cookies
            erfolgen auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der
            Websitebetreiber hat ein berechtigtes Interesse an der Analyse des
            Nutzerverhaltens, um sowohl sein Webangebot als auch seine Werbung
            zu optimieren. Sofern eine entsprechende Einwilligung abgefragt
            wurde (z. B. eine Einwilligung zur Speicherung von Cookies), erfolgt
            die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit.
            a DSGVO; die Einwilligung ist jederzeit widerrufbar.
          </p>{' '}
          <p>
            <strong>Deaktivieren von Hotjar</strong>
          </p>{' '}
          <p>
            Wenn Sie die Datenerfassung durch Hotjar deaktivieren möchten,
            klicken Sie auf folgenden Link und folgen Sie den dortigen
            Anweisungen:{' '}
            <a
              href="https://www.hotjar.com/opt-out"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.hotjar.com/opt-out
            </a>
          </p>{' '}
          <p>
            Bitte beachten Sie, dass die Deaktivierung von Hotjar für jeden
            Browser bzw. für jedes Endgerät separat erfolgen muss.
          </p>{' '}
          <p>
            Nähere Informationen über Hotjar und zu den erfassten Daten
            entnehmen Sie der Datenschutzerklärung von Hotjar unter dem
            folgenden Link:{' '}
            <a
              href="https://www.hotjar.com/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.hotjar.com/privacy
            </a>
          </p>
          <h2>5. Newsletter</h2>
          <h3>Newsletterdaten</h3>
          <p>
            Wenn Sie den auf der Website angebotenen Newsletter beziehen
            möchten, benötigen wir von Ihnen eine E-Mail-Adresse sowie
            Informationen, welche uns die Überprüfung gestatten, dass Sie der
            Inhaber der angegebenen E-Mail-Adresse sind und mit dem Empfang des
            Newsletters einverstanden sind. Weitere Daten werden nicht bzw. nur
            auf freiwilliger Basis erhoben. Diese Daten verwenden wir
            ausschließlich für den Versand der angeforderten Informationen und
            geben diese nicht an Dritte weiter.
          </p>
          <p>
            Die Verarbeitung der in das Newsletteranmeldeformular eingegebenen
            Daten erfolgt ausschließlich auf Grundlage Ihrer Einwilligung (Art.
            6 Abs. 1 lit. a DSGVO). Die erteilte Einwilligung zur Speicherung
            der Daten, der E-Mail-Adresse sowie deren Nutzung zum Versand des
            Newsletters können Sie jederzeit widerrufen, etwa über den
            „Austragen“-Link im Newsletter. Die Rechtmäßigkeit der bereits
            erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
          </p>
          <p>
            Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten
            Daten werden von uns bis zu Ihrer Austragung aus dem Newsletter
            gespeichert und nach der Abbestellung des Newsletters gelöscht.
            Daten, die zu anderen Zwecken bei uns gespeichert wurden (z.B.
            E-Mail-Adressen für den Mitgliederbereich) bleiben hiervon
            unberührt.
          </p>
          <h2>6. Plugins und Tools</h2>
          <h3>Web Fonts von fonts.com</h3>
          <p>
            Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so
            genannte Web Fonts, die von fonts.com, einer Dienstleistung
            der&nbsp;Monotype GmbH, Werner-Reimers-Straße 2-4, 61352 Bad
            Homburg, bereitgestellt werden. Beim Aufruf einer Seite lädt Ihr
            Browser die benötigten Web Fonts in Ihren Browsercache, um Texte und
            Schriftarten korrekt anzuzeigen.
          </p>
          <p>
            Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu
            den Servern von fonts.com aufnehmen. Hierdurch erlangt fonts.com
            Kenntnis darüber, dass über Ihre IP-Adresse unsere Website
            aufgerufen wurde. Die Nutzung von fonts.com erfolgt im Interesse
            einer einheitlichen und ansprechenden Darstellung unserer
            Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne von
            Art. 6 Abs. 1 lit. f DSGVO dar.
          </p>
          <p>
            Wenn Ihr Browser Web Fonts nicht unterstützt, wird eine
            Standardschrift von Ihrem Computer genutzt.
          </p>
          <p>
            Die Datenschutzerklärung von fonts.com finden Sie unter:{' '}
            <a
              href="https://www.fonts.com/info/legal/privacy"
              target="_blank"
              rel="noopener"
            >
              https://www.fonts.com/info/legal/privacy
            </a>
            .
          </p>
          <h3>Google Web Fonts</h3>
          <p>
            Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so
            genannte Web Fonts, die von Google bereitgestellt werden. Beim
            Aufruf einer Seite lädt Ihr Browser die benötigten Web Fonts in
            Ihren Browsercache, um Texte und Schriftarten korrekt anzuzeigen.
          </p>
          <p>
            Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu
            den Servern von Google aufnehmen. Hierdurch erlangt Google Kenntnis
            darüber, dass über Ihre IP-Adresse unsere Website aufgerufen wurde.
            Die Nutzung von Google Web Fonts erfolgt im Interesse einer
            einheitlichen und ansprechenden Darstellung unserer Online-Angebote.
            Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1
            lit. f DSGVO dar.
          </p>
          <p>
            Wenn Ihr Browser Web Fonts nicht unterstützt, wird eine
            Standardschrift von Ihrem Computer genutzt.
          </p>
          <p>
            Weitere Informationen zu Google Web Fonts finden Sie unter{' '}
            <a
              href="https://developers.google.com/fonts/faq"
              target="_blank"
              rel="noopener"
            >
              https://developers.google.com/fonts/faq
            </a>{' '}
            und in der Datenschutzerklärung von Google:{' '}
            <a
              href="https://www.google.com/policies/privacy/"
              target="_blank"
              rel="noopener"
            >
              https://www.google.com/policies/privacy/
            </a>
            .
          </p>
          <h3>Google Maps</h3>
          <p>
            Diese Seite nutzt über eine API den Kartendienst Google Maps.
            Anbieter ist die Google Inc., 1600 Amphitheatre Parkway, Mountain
            View, CA 94043, USA.
          </p>
          <p>
            Zur Nutzung der Funktionen von Google Maps ist es notwendig, Ihre IP
            Adresse zu speichern. Diese Informationen werden in der Regel an
            einen Server von Google in den USA übertragen und dort gespeichert.
            Der Anbieter dieser Seite hat keinen Einfluss auf diese
            Datenübertragung.
          </p>
          <p>
            Die Nutzung von Google Maps erfolgt im Interesse einer ansprechenden
            Darstellung unserer Online-Angebote und an einer leichten
            Auffindbarkeit der von uns auf der Website angegebenen Orte. Dies
            stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f
            DSGVO dar.
          </p>
          <p>
            Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der
            Datenschutzerklärung von Google:{' '}
            <a
              href="https://www.google.de/intl/de/policies/privacy/"
              target="_blank"
              rel="noopener"
            >
              https://www.google.de/intl/de/policies/privacy/
            </a>
            .
          </p>
        </div>
      </section>

      <FooterWaves />
    </Layout>
  );
}

export default Datenschutzerklaerung;
